import * as Turbo from "@hotwired/turbo"
import { Application } from "@hotwired/stimulus"
import { definitionsFromGlob } from "stimulus-vite-helpers"
import "./services/rollbar"

/*
 * Google Analytics Tracking
 */
declare global {
    interface Window {
        dataLayer: any
    }
}

window.dataLayer = window.dataLayer || []

function gtag(key: string, value: any, props?: any) {
    window.dataLayer.push(arguments)
}

const gtmScript = document.getElementById("gtm")
if (gtmScript) {
    const ANALYTICS_ID = gtmScript.dataset["id"]

    gtag("js", new Date())
    gtag("config", ANALYTICS_ID)

    let isInitialLoad = true
    document.addEventListener("turbo:load", () => {
        if (isInitialLoad) {
            isInitialLoad = false
            return
        }
        gtag("config", ANALYTICS_ID, {
            page_path: window.location.pathname,
        })
    })
}

const application = Application.start()
const controllers = import.meta.globEager("./controllers/*_controller.ts")
application.load(definitionsFromGlob(controllers))

Turbo.start()

//Required because we set isolatedModules to true in tsconfig as per vite recommendation
//https://vitejs.dev/guide/features.html#isolatedmodules
export {}
