import ShofskyController from "./ShofskyController"

import "@interactjs/auto-start"
import "@interactjs/core/Interactable"
import "@interactjs/actions/drag"
import "@interactjs/actions/drag/plugin"
import GalleryEvent from "../events/GalleryEvent"

export default class extends ShofskyController {
    static targets = ["thumbnail"]

    static values = {
        id: String,
    }

    declare readonly idValue: string

    declare readonly thumbnailTargets: HTMLLinkElement[]

    open(event: Event) {
        document.dispatchEvent(
            new GalleryEvent(
                "open",
                this.idValue,
                this.thumbnailTargets.indexOf(event.target as HTMLLinkElement)
            )
        )
    }
}
