import { Controller } from "@hotwired/stimulus"

export default class CoreValuesController extends Controller<HTMLImageElement> {
    static targets = ["popup"]

    declare readonly popupTargets: HTMLElement[]

    mouseEnterHandler: EventListener
    mouseLeaveHandler: EventListener

    connect() {
        this.mouseEnterHandler = this.mouseEnter.bind(this)
        this.mouseLeaveHandler = this.mouseLeave.bind(this)

        for (const rect of this.element.getElementsByTagName("rect")) {
            rect.addEventListener("mouseenter", this.mouseEnterHandler)
            rect.addEventListener("mouseleave", this.mouseLeaveHandler)
            rect.style.cursor = "pointer"
        }

        console.log(this.targets.findAll("popup"))
        console.log(this.popupTargets)

        this.resize()
    }

    disconnect() {
        for (const rect of this.element.getElementsByTagName("rect")) {
            rect.removeEventListener("mouseenter", this.mouseEnterHandler)
            rect.removeEventListener("mouseleave", this.mouseLeaveHandler)
        }
    }

    /*
     * Position the popups by lining up the circles in the svg with the center of the top div
     * of the popup which holds the popup icon
     */
    resize() {
        const imgRect = this.element.getBoundingClientRect()
        for (const popup of this.popupTargets) {
            const matchingIcon = this.element.querySelector(
                `#${popup.dataset.popupId}-icon`
            )
            if (matchingIcon == null) continue

            const iconRect = matchingIcon.getBoundingClientRect()
            const popupIconRect = popup
                .getElementsByTagName("div")[0]
                .getBoundingClientRect()

            popup.style.top = `${
                iconRect.top -
                imgRect.top -
                (popupIconRect.height - iconRect.height) / 2
            }px`
            popup.style.left = `${
                iconRect.left -
                imgRect.left -
                (popup.getBoundingClientRect().width - iconRect.width) / 2
            }px`
        }
    }

    mouseEnter(event: Event) {
        const targetId = (event.target as SVGElement).id
        const popup = this.popupTargets.find(
            (popup) => popup.dataset.popupId == targetId
        )
        if (!popup) return

        popup.classList.remove("opacity-0")
    }

    mouseLeave(event: Event) {
        const targetId = (event.target as SVGElement).id
        const popup = this.popupTargets.find(
            (popup) => popup.dataset.popupId == targetId
        )
        if (!popup) return

        popup.classList.add("opacity-0")
    }
}
