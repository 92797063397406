import ShofskyController from "./ShofskyController"

import "@interactjs/auto-start"
import "@interactjs/core/Interactable"
import "@interactjs/actions/drag"
import "@interactjs/actions/drag/plugin"
import interact from "@interactjs/interact"
import GalleryEvent from "../events/GalleryEvent"

export default class extends ShofskyController<HTMLElement> {
    static values = {
        id: String,
    }

    static targets = ["indicator", "track", "slide", "counter"]

    declare readonly idValue: string

    declare readonly indicatorTargets: HTMLInputElement[]
    declare readonly slideTargets: HTMLElement[]
    declare readonly trackTarget: HTMLElement
    declare readonly counterTarget: HTMLElement

    currentIndex: number = 0

    connect() {
        this.addEventListener(document, "gallery-open", this.onGalleryOpen)
        this.counterTarget.innerText = `${this.index + 1}/${this.numSlides}`
        return
        if (this.indicatorTargets && this.indicatorTargets.length > 0) {
            this.addEventListener(this.trackTarget, "scroll", this.onScroll)
        }

        if (this.isTouchEnabled) return //On touch screens horizontal scroll will be handled for us

        interact(this.trackTarget, {
            // @ts-ignore
            styleCursor: false,
        }).draggable({
            origin: "self",
            listeners: {
                start: (event) => {
                    this.trackTarget.classList.remove(
                        "snap-x",
                        "snap-mandatory",
                        "scroll-smooth"
                    )
                },
                move: (event) => {
                    this.trackTarget.style.cursor = "grab"
                    this.trackTarget.scrollLeft -= event.dx
                },
                end: () => {
                    this.trackTarget.classList.add("scroll-smooth")
                    this.element.clientWidth
                    const index = Math.round(
                        this.trackTarget.scrollLeft / this.element.clientWidth
                    )
                    this.trackTarget.scrollTo(
                        this.element.clientWidth * index - 1,
                        0
                    )
                },
            },
        })

        this.trackTarget.style.cursor = "grab"
    }

    onGalleryOpen(event: GalleryEvent) {
        if (event.id != this.idValue) return

        this.show(this.element)
    }

    previous() {
        if (this.index == 0) return

        this.index--
    }

    next() {
        if (this.index == this.numSlides - 1) return

        this.index++
    }

    close() {
        this.hide(this.element)
    }

    zoom() {}

    get index() {
        return this.currentIndex
    }

    set index(value: number) {
        this.currentIndex = value
        this.trackTarget.scrollTo(
            this.element.clientWidth * this.currentIndex,
            0
        )
        this.counterTarget.innerText = `${this.index + 1}/${this.numSlides}`
    }

    get numSlides() {
        return this.slideTargets.length
    }

    /*
     * Update the indicator targets based on the scroll position
     */
    onScroll(event: Event) {
        this.element.clientWidth
        const index = Math.round(
            this.trackTarget.scrollLeft / this.element.clientWidth
        )
        this.indicatorTargets[index].checked = true
    }
}
