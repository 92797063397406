import ShofskyController from "./ShofskyController"

export default class SkipNavController extends ShofskyController<HTMLImageElement> {
    connect() {
        this.addEventListener(this.element, "click", this.onClick)
        this.addEventListener(this.element, "keypress", this.onClick)
    }

    onClick(event: Event) {
        if (
            event instanceof KeyboardEvent &&
            ![" ", "Enter"].includes(event.key)
        ) {
            return
        }

        const href = (event.currentTarget as HTMLLinkElement).href.split("#")[1]

        const navigateTarget = document.getElementById(href)

        if (!navigateTarget) {
            return
        }

        const removeTabIndex = function (event: Event) {
            ;(event.currentTarget as HTMLElement).removeAttribute("tabindex")
            event.currentTarget.removeEventListener("blur", removeTabIndex)
            event.currentTarget.removeEventListener("focusout", removeTabIndex)
        }

        navigateTarget.setAttribute("tabindex", "-1")
        ;["blur", "focusout"].forEach((eventType) =>
            navigateTarget.addEventListener(eventType, removeTabIndex)
        )

        navigateTarget.focus()
    }
}
