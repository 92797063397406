import ShofskyController from "./ShofskyController"
import algoliasearch from "algoliasearch"
import * as Turbo from "@hotwired/turbo"
import { Hit, SearchResponse } from "instantsearch.js"

export default class extends ShofskyController {
    static targets = [
        "keywordText",
        "noResultText",
        "results",
        "resultsTemplate",
        "paginator",
        "paginatorPageButtons",
        "paginatorButtonTemplate",
        "paginatorLabel",
    ]

    static values = {
        noResultText: String,
        keywordText: String,
    }

    declare readonly keywordTextValue: string
    declare readonly noResultTextValue: string

    declare readonly keywordTextTarget: HTMLElement
    declare readonly noResultTextTarget: HTMLElement
    declare readonly resultsTarget: HTMLElement
    declare readonly paginatorTarget: HTMLElement
    declare readonly paginatorPageButtonsTarget: HTMLElement
    declare readonly paginatorLabelTarget: HTMLElement

    declare readonly resultsTemplateTarget: HTMLTemplateElement
    declare readonly paginatorButtonTemplateTarget: HTMLTemplateElement

    hits: Hit[] = []
    currentPageNum = 1
    numPages = 0
    itemsPerPage = 10

    connect() {
        const keyword = new URLSearchParams(window.location.search).get("q")

        if (!keyword || keyword.length < 3) {
            Turbo.visit("/")
        }

        // Connect and authenticate with your Algolia app
        const client = algoliasearch(
            "NJAGWC3YNU",
            "9ea8e7b45514dadeac244ed3c6fa00c2"
        )

        // Create a new index and add a record
        const index = client.initIndex("default")

        // Search the index and print the results
        index
            .search(keyword, {
                hitsPerPage: 50,
            })
            .then(this.showResults.bind(this))
    }

    showResults(response: SearchResponse<any>) {
        this.hits = response.hits.filter(
            (hit) =>
                hit._snippetResult &&
                hit._snippetResult.content &&
                hit.url &&
                hit.title
        )

        if (this.hits.length > 0) {
            this.currentPageNum = 1
            this.numPages = Math.ceil(this.hits.length / this.itemsPerPage)
            this.setResults(this.hits.slice(0, this.itemsPerPage))
            this.setPagination(Math.ceil(response.nbHits / this.itemsPerPage))

            this.keywordTextTarget.innerText = this.keywordTextValue.replace(
                "%KEYWORD%",
                response.query
            )
            this.show(this.resultsTarget)

            if (response.nbHits > this.itemsPerPage) {
                this.show(this.paginatorTarget)
            } else {
                this.hide(this.paginatorTarget)
            }

            this.show(this.keywordTextTarget)
            return
        }

        this.numPages = 0

        this.hide(this.resultsTarget)
        this.hide(this.paginatorTarget)
        this.hide(this.keywordTextTarget)
        this.noResultTextTarget.innerText = this.noResultTextValue.replace(
            "%KEYWORD%",
            response.query
        )
        this.show(this.noResultTextTarget)
    }

    setResults(hits: Hit[]) {
        const results = hits.map((hit) => {
            const result = this.resultsTemplateTarget.content.cloneNode(
                true
            ) as HTMLElement
            result.querySelector("h5").innerHTML = hit.title
            result.querySelector("p").innerHTML =
                hit._snippetResult.content["value"]
            result.querySelector("a").href = hit.url

            return result as Node
        })

        if (results.length > 0) {
            this.resultsTarget.replaceChildren(...results)
        }

        this.paginatorLabelTarget.innerText = `Page ${
            this.currentPageNum
        } of ${Math.ceil(this.hits.length / this.itemsPerPage)}`
    }

    goto(event: Event) {
        this.currentPageNum = parseInt((event.target as HTMLInputElement).value)
        this.setResults(
            this.hits.slice(
                (this.currentPageNum - 1) * this.itemsPerPage,
                (this.currentPageNum - 1) * this.itemsPerPage + 11
            )
        )
    }

    previous() {
        if (this.currentPageNum <= 1) return

        this.currentPageNum--
        this.selectButton(this.currentPageNum)
    }

    next() {
        if (this.currentPageNum >= this.numPages) return

        this.currentPageNum++
        this.selectButton(this.currentPageNum)
    }

    private selectButton(pageNum: number) {
        const input = this.paginatorPageButtonsTarget.querySelectorAll("input")[
            pageNum - 1
        ] as HTMLInputElement

        input.checked = true
        input.dispatchEvent(
            new Event("change", {
                bubbles: true,
            })
        )
    }

    setPagination(numPages: number) {
        if (numPages == 1) {
            this.hide(this.paginatorTarget)
            return
        }

        this.show(this.paginatorTarget)
        for (let pageNum = 1; pageNum <= numPages; pageNum++) {
            const button = this.paginatorButtonTemplateTarget.content.cloneNode(
                true
            ) as HTMLElement
            const input = button.querySelector("input")
            input.value = pageNum.toString()
            input.id = `page-${pageNum}`
            if (pageNum == 1) {
                input.checked = true
            }

            const label = button.querySelector("label")
            label.innerText = pageNum.toString()
            label.htmlFor = `page-${pageNum}`

            this.paginatorPageButtonsTarget.appendChild(button)
        }
    }
}
