import { Controller } from "@hotwired/stimulus"
import { Map, MapboxOptions } from "mapbox-gl"
import ShofskyController from "./ShofskyController"

declare global {
    interface Window {
        mapboxgl: any
    }
}

interface Location {
    name: String
    address: String
    coordinate: number[]
}

const placesLayer: any = {
    id: "places",
    type: "circle",
    source: "places",
    paint: {
        "circle-color": "#00a1de",
        "circle-radius-transition": {
            duration: 300,
        },
        "circle-radius": [
            "interpolate",
            ["linear"],
            ["to-number", ["boolean", ["feature-state", "expand"], false]],
            0,
            ["case", ["has", "point_count"], 6, 4],
            1,
            ["case", ["has", "point_count"], 8, 6],
        ],
        "circle-opacity-transition": {
            duration: 300,
        },
        "circle-opacity": [
            "interpolate",
            ["linear"],
            ["to-number", ["boolean", ["feature-state", "faded"], false]],
            0,
            1,
            1,
            0.5,
        ],
    },
}
const officeLabelsLayer: any = {
    id: "office-label",
    type: "symbol",
    source: "labels",
    paint: {
        "text-color": "#ffffff",
        "text-opacity-transition": {
            duration: 300,
        },
        "text-opacity": [
            "case",
            //'interpolate', ['linear'],
            ["boolean", ["feature-state", "faded"], false],
            0.5,
            1,
        ],
    },
    layout: {
        "text-field": ["get", "title"],
        "text-font": ["Roboto Bold"],
        "text-allow-overlap": true,
        "text-padding": 3,
        "text-size": 12,
        "text-variable-anchor": [
            "top",
            "top-left",
            "top-right",
            "bottom",
            "bottom-left",
        ],
        "text-radial-offset": 0.75,
        "text-justify": "auto",
    },
}

export default class extends ShofskyController {
    static values = {
        locations: Array,
        controls: String,
        center: String,
        token: String,
        stylesheet: String,
    }

    declare readonly centerValue: string
    declare readonly tokenValue: string
    declare readonly stylesheetValue: string
    declare readonly controlsValue: string
    declare readonly locationsValue: {
        name: String
        latlong: String
        address: String
    }[]

    private interval = 0
    private map: Map
    private hoverState = false

    connect() {
        this.addEventListener(document, "change", this.markerSelected)

        if (window.mapboxgl) {
            this.init()
            return
        }

        this.interval = window.setInterval(() => {
            if (window.mapboxgl) {
                window.clearInterval(this.interval)
                this.interval = 0
                this.init()
            }
        }, 100)
    }

    disconnect() {
        if (this.interval) {
            window.clearInterval(this.interval)
        }
    }

    init() {
        const mapbox = window.mapboxgl

        window.mapboxgl.accessToken = this.tokenValue

        this.map = new mapbox.Map({
            container: this.element.id,
            style: this.stylesheetValue, // stylesheet location
            bounds: this.bounds,
            fitBoundsOptions: {
                padding:
                    document.body.clientWidth > 1200
                        ? {
                              top: 20,
                              bottom: 20,
                              left: 80,
                              right: 80,
                          }
                        : {
                              top: 25,
                              bottom: 25,
                              left: 75,
                              right: 75,
                          },
            },
            scrollZoom: false, // scroll to zoom interaction
            boxZoom: false, // box zoom interaction
            dragRotate: false, // drag to rotate interaction
            dragPan: false, // drag to pan interaction
            doubleClickZoom: false, // double click to zoom interaction
            touchRotateZoom: false, // pinch to rotate and zoom interaction
            touchPitch: false, // drag to pitch interaction
            transition: {
                duration: 300,
                delay: 0,
            },
        } as MapboxOptions)

        if (this.center) this.map.setCenter(this.center)

        if (this.controlsValue.includes("fullscreen")) {
            this.map.addControl(new window.mapboxgl.FullscreenControl())
        }

        if (this.controlsValue.includes("navigation")) {
            this.map.addControl(new window.mapboxgl.NavigationControl())
        }

        this.map.once("load", () => this.onMapLoad())
    }

    private onMapLoad() {
        const sourceData: any = {
            type: "FeatureCollection",
            features: this.locations.map((location) => {
                return {
                    type: "Feature",
                    properties: {
                        title: location.name,
                        description: location.address
                            .replaceAll(/<\/?p>/g, "")
                            .trim(),
                    },
                    geometry: {
                        type: "Point",
                        coordinates: location.coordinate,
                    },
                }
            }),
        }

        // Add a GeoJSON source containing place coordinates and information.
        this.map.addSource("places", {
            type: "geojson",
            data: sourceData,
            generateId: true,
            cluster: true,
            clusterRadius: 10,
        })

        this.map.addSource("labels", {
            type: "geojson",
            data: sourceData,
            promoteId: "title",
        })

        // Add a layer showing the places.
        this.map.addLayer(placesLayer)

        if (document.body.clientWidth >= 1200) {
            this.map.addLayer(officeLabelsLayer)
        }

        // Create a popup, but don't add it to the map yet.
        const popup = new window.mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
            offset: 8,
            className: "fade",
        })

        this.map.on("mouseenter", "places", (e) => {
            // Change the cursor style as a UI indicator.
            this.map.getCanvas().style.cursor = "pointer"

            // Copy coordinates array.
            //@ts-ignore
            const coordinates = e.features[0].geometry.coordinates.slice()
            const description = e.features[0].properties.description

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
            }

            // Populate the popup and set its coordinates
            // based on the feature found.
            popup.setLngLat(coordinates).setHTML(description).addTo(this.map)
        })

        this.map.on("mouseleave", "places", () => {
            this.map.getCanvas().style.cursor = ""
            popup.remove()
        })

        this.map.on("mouseenter", "office-label", (event) => {
            if (document.body.clientWidth >= 1200) {
                this.hoverState = true

                // Change cursor style as a UI indicator
                this.map.getCanvas().style.cursor = "pointer"
                const coordinates =
                        //@ts-ignore
                        event.features[0].geometry.coordinates.slice(),
                    description = event.features[0].properties.description

                // Fade other label elements
                this.animateLabels(event.features[0].properties.title)

                this.animatePlaces(event.features[0].properties.title)

                this.animateOverlay()

                // If popup is already open close immediately
                if (this.hoverState === true) {
                    popup.remove()
                    popup.addClassName("fade")
                }

                // Open new popup
                popup
                    .setLngLat(coordinates)
                    .setHTML(description)
                    .addTo(this.map)
                popup.removeClassName("fade")
            }
        })

        this.map.on("mouseleave", "office-label", () => {
            if (document.body.clientWidth >= 1200) {
                this.hoverState = false

                this.map.getCanvas().style.cursor = ""

                this.removeOverlay()

                // Remove feature state
                this.map.removeFeatureState({ source: "labels" })

                this.map.removeFeatureState({ source: "places" })

                // Remove popup
                if (this.hoverState === false) {
                    popup.addClassName("fade")
                    setTimeout(() => {
                        if (this.hoverState === false) {
                            popup.remove()
                        }
                    }, 300)
                }
            }
        })
    }

    /**
     * Animate/add the overlay layer
     * @private
     */
    private animateOverlay() {
        // Add a background layer if not already (needed for quick mouseovers)
        if (!this.map.getLayer("overlay")) {
            this.map.addLayer(
                {
                    id: "overlay",
                    type: "background",
                    paint: {
                        "background-color": "#000b1f",
                        "background-opacity-transition": {
                            duration: 300,
                        },
                        "background-opacity": 0,
                    },
                },
                "places"
            )
        }
        if (this.hoverState === true) {
            this.map.setPaintProperty("overlay", "background-opacity", 0.5)
        }
    }

    /**
     * Remove overlay layer
     * @private
     */
    private removeOverlay() {
        if (this.map.getLayer("overlay") && this.hoverState === false) {
            this.map.setPaintProperty("overlay", "background-opacity", 0)
            setTimeout(() => {
                if (this.map.getLayer("overlay") && this.hoverState === false) {
                    this.map.removeLayer("overlay")
                }
            }, 300)
        }
    }

    private animateLabels(title: string) {
        // Fade other label elements
        const labels = this.map.querySourceFeatures("labels", {
            filter: ["!=", ["get", "title"], title],
        })

        for (const label of labels) {
            // Set feature state which will trigger the opacity
            this.map.setFeatureState(
                { source: "labels", id: label.id },
                { faded: true }
            )
        }
    }

    /**
     * Animate the places layer to fade out non-matches
     * @param properties Object of feature properties
     * @private
     */
    private animatePlaces(title: string) {
        let places = this.map.queryRenderedFeatures({
            //@ts-ignore
            layers: ["places"],
        })

        for (const place of places) {
            // If not a cluster dim
            if (
                typeof place.properties.cluster === "undefined" &&
                place.properties.title !== title
            ) {
                // Set feature state to enforce
                this.map.setFeatureState(
                    { source: "places", id: place.id },
                    { faded: true, expand: false }
                )
            } else if (place.properties.title !== title) {
                // If cluster then we must determine if the hover element is in the cluster
                this.map
                    .getSource("places")
                    //@ts-ignore
                    .getClusterLeaves(
                        place.properties.cluster_id,
                        100,
                        0,
                        (error, features) => {
                            // Check the features in the cluster and set the feature state for the circle accordingly
                            let faded = true

                            if (error) {
                                return
                            }

                            for (let feature of features) {
                                if (feature.properties.title === title) {
                                    faded = false
                                    break
                                }
                            }

                            if (faded) {
                                this.map.setFeatureState(
                                    { source: "places", id: place.id },
                                    { faded: true, expand: false }
                                )
                            } else {
                                this.map.setFeatureState(
                                    { source: "places", id: place.id },
                                    { faded: false, expand: true }
                                )
                            }
                        }
                    )
            } else {
                this.map.setFeatureState(
                    { source: "places", id: place.id },
                    { faded: false, expand: true }
                )
                // On mobile we'll make this circle grow a little
                /*if (document.body.clientWidth < 1200) {
                    this.map.setFeatureState(
                        { source: 'places', id: place.id },
                        { expand: true }
                    )
                }*/
            }
        }
    }

    private markerSelected(event: Event) {
        const target = event.target as HTMLElement
        if (!target.dataset.markerIndex) return

        if ((target as HTMLInputElement).checked) {
            this.animatePlaces(this.locations[target.dataset.markerIndex].name)
            this.animateOverlay()
        } else {
            this.removeOverlay()
            this.map.removeFeatureState({ source: "labels" })
            this.map.removeFeatureState({ source: "places" })
        }
    }

    private get bounds() {
        const bounds = this.locations.reduce((bounds, location) => {
            bounds.extend(location.coordinate.map((value) => value))

            return bounds
        }, new window.mapboxgl.LngLatBounds())

        return bounds
    }

    private get locations(): Location[] {
        return this.locationsValue
            .map((location) => {
                const [lat, lng] = location.latlong
                    .split(",")
                    .map((value) => parseFloat(value))

                // If not numbers then skip
                if (Number.isNaN(lat) || Number.isNaN(lng)) return null

                return {
                    name: location.name,
                    address: location.address,
                    coordinate: [lng, lat],
                }
            })
            .filter((location) => location !== null)
    }

    private get center(): [number, number] | null {
        return this.centerValue
            ? (this.centerValue
                  .split(",")
                  .map((value) => parseFloat(value)) as [number, number])
            : null
    }
}
