export default class GalleryEvent extends Event {
    readonly id: string
    readonly index: number

    constructor(type: string, id: string, index: number) {
        super(`gallery-${type}`)
        this.id = id
        this.index = index
    }
}
