import ShofskyController from "./ShofskyController"

export interface TargetData {
    id?: string
    dispatch?: string
    modalPosition?: string
    modalScrollTo?: string
}

export default class Modal_controller extends ShofskyController {
    headerHeight: number = 0
    currentScrollY: number = 0
    onKeydown: any
    initiatingElement: HTMLElement
    links: HTMLElement[]

    connect() {
        //Add this controller to body element so it can be called for other controllers
        this.element[this.identifier] = this

        const navElements = document.getElementsByTagName("nav")
        if (navElements.length) {
            this.headerHeight =
                document.getElementsByTagName("nav")[0].offsetHeight
        }
    }

    disconnect() {
        if (this.onKeydown) {
            document.removeEventListener("keydown", this.onKeydown)
        }
    }

    open(event: Event) {
        if (!this.isValidOpenEvent(event)) return
        event.preventDefault()

        this.initiatingElement = event.target as HTMLElement

        /* Attach the keydown listener so we can customize keyboard navigation */
        this.onKeydown = this.keydown.bind(this)
        document.addEventListener("keydown", this.onKeydown)

        const data = (event.currentTarget as HTMLElement).dataset as TargetData

        this.initContent(data)
        this.positionModal(data)

        if (data.dispatch) {
            event.currentTarget.dispatchEvent(
                new Event(data.dispatch, { bubbles: true })
            )
        }
    }

    /*
     * Modal can be opened via keypress but we need to check that only spaces and returns
     * open the modal
     */
    isValidOpenEvent(event: Event): Boolean {
        if (!(event instanceof KeyboardEvent)) {
            return true
        }

        if ([" ", "Enter"].includes((event as KeyboardEvent).key)) {
            return true
        }

        return false
    }

    initContent(data: TargetData) {
        /*
        if (data.id && window.location.pathname.includes("careers")) {
            window.history.pushState(
                {},
                "",
                window.location.origin +
                    window.location.pathname +
                    "/" +
                    data.id
            )
        }
        */

        let currentContent: HTMLElement
        this.contents.forEach((content) => {
            if (content.dataset.id == data.id) {
                content.classList.remove("hidden")
                currentContent = content
            } else {
                content.classList.add("hidden")
            }
        })

        this.initKeyBoardNavigation(currentContent)
    }

    initKeyBoardNavigation(content: HTMLElement) {
        /*
         * Set the focus on the first link/button to enable keyboard navigation
         */
        this.links = (
            Array.from(
                content.querySelectorAll("a, button, input, select")
            ) as HTMLElement[]
        ).filter((element: HTMLElement & { ariaHidden: string }) => {
            return !(
                element.ariaHidden == "true" ||
                (element instanceof HTMLInputElement &&
                    element.type == "hidden")
            )
        })

        if (this.links.length) {
            this.links[0].focus()
        }
    }

    positionModal(data) {
        let modalTop = 80,
            scrollTo = -1,
            fixWindowHeight = false
        if (window.matchMedia("(max-width: 1024px)").matches) {
            modalTop = 0
            scrollTo = 0
        } else {
            fixWindowHeight = false
            if (data.modalPosition) {
                modalTop =
                    window.scrollY +
                    document
                        .getElementById(data.modalPosition)
                        .getBoundingClientRect().top
            }

            if (data.modalScrollTo) {
                scrollTo =
                    window.scrollY +
                    document
                        .getElementById(data.modalScrollTo)
                        .getBoundingClientRect().top -
                    30
                modalTop = scrollTo + 60
            }
        }

        if (scrollTo < 0) {
            scrollTo = modalTop - 60
        }

        this.currentScrollY = window.scrollY
        window.scrollTo({
            top: scrollTo,
            behavior: "auto",
        })

        this.popup.style.marginTop = modalTop + "px"
        this.show(this.modal)
    }

    close() {
        if (this.onKeydown) {
            document.removeEventListener("keydown", this.onKeydown)
        }

        if (window.location.pathname.startsWith("/careers/")) {
            window.history.replaceState({}, "", "/careers")
        }

        this.contents.forEach((content) => {
            content.classList.remove("hidden")
        })

        this.hide(this.modal)

        document.body.classList.remove("with-mobile-nav")

        window.scrollTo({
            top: this.currentScrollY,
            behavior: "auto",
        })

        if (this.initiatingElement) {
            this.initiatingElement.focus()
        }
    }

    keydown(event: KeyboardEvent) {
        switch (event.key) {
            case "Tab":
                if (!this.links || this.links.length == 0) {
                    return
                }

                const target = event.target as HTMLElement
                let nextLink: HTMLElement = this.links[0]

                this.links.forEach((link, index) => {
                    if (link != target) {
                        link.tabIndex = -1
                        return
                    }

                    if (event.shiftKey) {
                        nextLink =
                            index == 0
                                ? this.links[this.links.length - 1]
                                : this.links[index - 1]
                    } else {
                        nextLink =
                            index < this.links.length - 1
                                ? this.links[index + 1]
                                : this.links[0]
                    }
                })

                nextLink.tabIndex = 0
                nextLink.focus()

                event.preventDefault()
                break

            case "Escape":
                event.preventDefault()
                this.close()
        }
    }

    get popup(): HTMLElement {
        return this.targets.find("popup") as HTMLElement
    }

    get modal(): HTMLElement {
        return this.targets.find("modal") as HTMLElement
    }

    get background(): HTMLElement {
        return this.targets.find("background") as HTMLElement
    }

    get contents(): HTMLElement[] {
        return this.targets.findAll("content") as HTMLElement[]
    }
}
