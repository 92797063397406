import Rollbar from "rollbar"

declare global {
    interface Window {
        rollbar: Rollbar
    }
}

const host = window.location.host

const rollbar: Rollbar = new Rollbar({
    accessToken: "3626dec506b14769a16ed39fc0daa1d2",
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true, //!host.includes('localhost'),
    logLevel: "error",
    autoInstrument: {
        network: false,
        log: true,
        dom: true,
        navigation: true,
        connectivity: true,
        contentSecurityPolicy: true,
        errorOnContentSecurityPolicy: false,
    },
    payload: {
        environment: host.includes("staging") ? "production" : "staging",
    },
})

export default rollbar
