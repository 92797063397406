import ShofskyController from "./ShofskyController"

export default class extends ShofskyController {
    connect() {
        this.addEventListener(document.body, "keydown", this.onKeydown)
        this.addEventListener(document.body, "click", this.onClick)
    }

    onKeydown(event: KeyboardEvent) {
        if (event.key == "Tab") {
            document.body.classList.add("with-focus")
        }
    }

    onClick(event: Event) {
        document.body.classList.remove("with-focus")
    }
}
