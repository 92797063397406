import { Controller } from "@hotwired/stimulus"

export default class FileInputController extends Controller<HTMLImageElement> {
    static targets = ["filename", "input"]

    declare readonly filenameTarget: HTMLElement
    declare readonly inputTarget: HTMLInputElement

    updateFile() {
        this.filenameTarget.innerText =
            this.inputTarget.files.length == 0
                ? ""
                : this.inputTarget.files[0].name.match(/([^\\\/]*)$/gm)[0] ?? ""
    }
}
