import ShofskyController from "./ShofskyController"

export default class FormController extends ShofskyController<HTMLFormElement> {
    static values = {
        conditions: Object,
    }

    static targets = ["field", "fieldWrapper"]

    declare readonly conditionsValue: object

    declare readonly fieldTargets: HTMLInputElement[]
    declare readonly fieldWrapperTargets: HTMLElement[]
    declare readonly inputTarget: HTMLInputElement

    connect() {
        if (!this.conditionsValue) return

        const observedInputs: HTMLInputElement[] = []

        for (const field in this.conditionsValue) {
            for (const [conditionType, conditions] of Object.entries(
                this.conditionsValue[field]
            )) {
                for (const [field, condition] of Object.entries(conditions)) {
                    const input = this.fieldTargets.find(
                        (target) => target.dataset.field == field
                    )
                    input && observedInputs.push(input)
                }
            }
        }

        for (const input of observedInputs) {
            this.addEventListener(
                input,
                "change",
                this.updateConditions.bind(this)
            )
        }

        this.updateConditions()
    }

    private updateConditions() {
        for (const field in this.conditionsValue) {
            for (const [conditionType, conditions] of Object.entries(
                this.conditionsValue[field]
            )) {
                for (const [dependsOnField, condition] of Object.entries(
                    conditions
                )) {
                    const [input, inputWrapper, dependsInput] = [
                        this.fieldTargets.find(
                            (target) => target.dataset.field == field
                        ),
                        this.fieldWrapperTargets.find(
                            (target) => target.dataset.field == field
                        ),
                        this.fieldTargets.find(
                            (target) => target.dataset.field == dependsOnField
                        ),
                    ]

                    if (!(inputWrapper && input && dependsInput)) {
                        continue
                    }

                    if (dependsInput.value == condition.split(" ")[1]) {
                        this.show(inputWrapper)
                    } else {
                        this.hide(inputWrapper)
                        input.value = ""
                    }
                }
            }
        }
    }
}
