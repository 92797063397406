import ShofskyController from "./ShofskyController"
import * as Turbo from "@hotwired/turbo"

export default class SearchBoxController extends ShofskyController {
    static targets = ["input"]

    declare readonly inputTarget: HTMLInputElement

    search(event: Event) {
        if (this.inputTarget.value.length < 3) return

        Turbo.visit(`/search?q=${encodeURIComponent(this.inputTarget.value)}`)
    }
}
