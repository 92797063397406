import { Controller } from "@hotwired/stimulus"

export default class LazyloadController extends Controller<HTMLImageElement> {
    static values = {
        target: String,
    }

    declare readonly targetValue: string

    target: HTMLElement

    _label: HTMLLabelElement

    toggled: boolean
    onTransitioned: EventListenerOrEventListenerObject

    connect() {
        this.target = document.getElementById(this.targetValue)

        if (!this.target) return

        this.onTransitioned = this.transitioned.bind(this)
        this.target.classList.remove("peer-checked:h-auto")
        this.toggled = this.target.classList.contains("h-auto")
    }

    toggle() {
        this.toggled = this.toggled ? this.collapse() : this.expand()
    }

    private collapse(): boolean {
        if (!this.target) return

        this.label.setAttribute("aria-expanded", "false")
        this.target.setAttribute("aria-hidden", "true")

        // get the height of the element's inner content, regardless of its actual size
        const targetHeight = this.target.scrollHeight

        // temporarily disable all css transitions
        const transition = this.target.style.transition
        this.target.style.transition = ""

        // on the next frame (as soon as the previous style change has taken effect),
        // explicitly set the element's height to its current pixel height, so we
        // aren't transitioning out of 'auto'
        requestAnimationFrame(() => {
            this.target.style.height = targetHeight + "px"
            this.target.style.transition = transition

            // on the next frame (as soon as the previous style change has taken effect),
            // have the element transition to height: 0
            requestAnimationFrame(() => {
                this.target.style.height = 0 + "px"
            })
        })

        // mark the section as "currently collapsed"
        this.target.setAttribute("data-collapsed", "true")

        return false
    }

    private expand(): boolean {
        if (!this.target) return

        this.label.setAttribute("aria-expanded", "true")
        this.target.setAttribute("aria-hidden", "false")

        // get the height of the element's inner content, regardless of its actual size
        const targetHeight = this.target.scrollHeight

        // have the this.target transition to the height of its inner content
        this.target.style.height = targetHeight + "px"

        // when the next css transition finishes (which should be the one we just triggered)
        this.target.addEventListener("transitionend", this.onTransitioned)

        // mark the section as "currently not collapsed"
        this.target.setAttribute("data-collapsed", "false")

        return true
    }

    private transitioned() {
        this.target.removeEventListener("transitionend", this.onTransitioned)
    }

    get label(): HTMLLabelElement {
        return (
            this._label ??
            (this._label = document.querySelector(
                `label[for='${this.element.id}']`
            ))
        )
    }
}
