import ShofskyController from "./ShofskyController"

export default class extends ShofskyController<HTMLElement> {
    static targets = ["toggleButton", "input", "searchButton"]

    declare readonly toggleButtonTarget: HTMLElement
    declare readonly searchButtonTarget: HTMLElement
    declare readonly inputTarget: HTMLInputElement

    connect() {
        this.addEventListener(this.element, "keypress", this.onKeypress)
        //this.addEventListener(document, "keydown", this.onKeydown)
        // this.addEventListener(this.element, "keyup", this.onKeydown)
    }

    onKeypress(event: KeyboardEvent) {
        if (event.target == this.toggleButtonTarget && event.key == " ") {
            event.preventDefault()
            this.toggleButtonTarget.click()
        }
    }

    onKeydown(event: KeyboardEvent) {
        if (event.target == this.toggleButtonTarget) {
            return
        }

        if (event.key == "Escape") {
            event.preventDefault()
            this.toggleButtonTarget.click()
        }
    }

    setFocus(event: Event) {
        if ((event.target as HTMLInputElement).value) {
            this.inputTarget.focus()
        }
    }

    hideSearch(event: Event) {
        setTimeout(() => {
            if (
                document.activeElement != this.inputTarget &&
                document.activeElement != this.searchButtonTarget
            ) {
                this.toggleButtonTarget.click()
            }
        }, 50)
    }
}
