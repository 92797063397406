import ShofskyController from "./ShofskyController"

export default class extends ShofskyController {
    static targets = ["button", "dropdown", "select", "sizer"]

    declare readonly buttonTarget: HTMLButtonElement
    declare readonly dropdownTarget: HTMLElement
    declare readonly sizerTarget: HTMLElement
    declare readonly selectTarget: HTMLSelectElement

    connect() {
        this.addEventListener(this.selectTarget, "change", this.onChange)
        this.onChange()
    }

    private onChange() {
        this.buttonTarget.innerText = [...this.selectTarget.options]
            .find((option) => option.value == this.selectTarget.value)
            .innerText.trim()
        this.selectTarget.style.width = `${this.sizerTarget.clientWidth}px`

        this.sizerTarget.innerText =
            this.selectTarget.selectedOptions[0].innerText.trim()
        this.selectTarget.style.width = `${this.sizerTarget.clientWidth}px`
    }

    showDropdown(event: Event) {
        event.preventDefault()
        this.show(this.dropdownTarget)
    }

    hideDropdown() {
        this.hide(this.dropdownTarget)
    }

    selectOption(event: Event) {
        event.preventDefault()
        const target = event.target as HTMLElement

        if (this.selectTarget.value != target.dataset["value"]) {
            this.selectTarget.value = target.dataset["value"]
            this.selectTarget.dispatchEvent(new Event("change"))
        }

        this.hideDropdown()
    }
}
