import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["item", "select"]

    declare readonly itemTargets: HTMLElement[]
    declare readonly selectTargets: HTMLSelectElement[]

    apply(event: Event) {
        const value = (event.target as HTMLSelectElement).value
        for (const select of this.selectTargets) {
            if (select != event.target) {
                if (select.value != select.options[0].value) {
                    select.value = select.options[0].value
                    select.dispatchEvent(new Event("change"))
                }
            }
        }

        for (const item of this.itemTargets) {
            if (!value || item.dataset["filterValue"].trim().includes(value)) {
                item.classList.remove("hidden")
            } else {
                item.classList.add("hidden")
            }
        }
    }
}
