import { Controller } from "@hotwired/stimulus"

export default class ViewFormController extends Controller<HTMLImageElement> {
    static targets = ["button", "form"]

    declare readonly buttonTarget: HTMLElement
    declare readonly formTarget: HTMLElement

    show() {
        this.buttonTarget.classList.add("hidden")
        this.formTarget.classList.remove("hidden")
        this.buttonTarget.setAttribute("aria-expanded", "true")
        this.buttonTarget.setAttribute("aria-hidden", "true")
        this.formTarget.setAttribute("aria-hidden", "false")
    }
}
